'use client';

import {
  RefObject, useEffect, useState,
} from 'react';

/* Detect if ref element is in viewport */
export default function useInViewport(ref: RefObject<Element>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    ref.current && observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return isIntersecting;
}
