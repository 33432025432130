import { useAtomValue } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import clientDeviceSize, { ScreenSize } from '../store/deviceScreenSize';
import useInViewport from '../useInViewport';
import styles from './FadeUpAnimatedText.module.scss';

interface FadeUpAnimatedTextProps {
  text: string | string[],
}

const FadeUpAnimatedTextContent = ({ text }: FadeUpAnimatedTextProps) => {
  const textContainer = useRef<HTMLSpanElement>(null);
  const isTextVisible = useInViewport(textContainer);
  const [textOnScreen, setTextOnScreen] = useState<boolean>(false);
  const [textWidth, setTextWidth] = useState<number>(150);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  let currentTitleIndex = useRef<number>(0);
  let titleSliderInterval: ReturnType<typeof setInterval>;

  useEffect(() => {
    isTextVisible && setTextOnScreen(true);
  }, [isTextVisible]);

  const updateTextLength = () => {
    if (textContainer.current) {
      let length = 2;
      let textSelector;

      if (Array.isArray(text)) {
        const longestWord = text.reduce((longest, word) => (word.length > longest.length ? word : longest), text[0]);
        const longestWordIndex = text.findIndex(word => word === longestWord);
        textSelector = textContainer.current.querySelector(`[data-id="${longestWordIndex}"]`);
      } else {
        textSelector = textContainer.current.querySelector(`.${styles.text}`);
      }
      const letters = textSelector?.querySelectorAll(`.${styles.letter}`);

      letters?.forEach(letter => {
        if (letter instanceof HTMLElement) {
          length += letter.offsetWidth;
        }
      });
      setTextWidth(length);
    }
  };

  useEffect(() => {
    const updateTextTimeout = setTimeout(updateTextLength, 500);
    return () => clearTimeout(updateTextTimeout);
  }, []);

  useEffect(updateTextLength, [deviceSize]);

  const showTitle = (index?: number) => {
    if (textContainer.current) {
      const titleText = textContainer.current.querySelector(Array.isArray(text) ? `[data-id="${index}"]` : `.${styles.text}`);
      if (titleText) titleText.classList.add(styles.text_middle);
    }
  };

  const hideTitle = (index: number) => {
    if (textContainer.current) {
      const titleText = textContainer.current.querySelector(`[data-id="${index}"]`);
      if (titleText) {
        titleText.classList.remove(styles.text_middle);
        titleText.classList.add(styles.text_up);

        setTimeout(() => {
          titleText.classList.remove(styles.text_up);
        }, 2000);
      }
    }
  };

  const cycleTitle = () => {
    const titleText = textContainer.current?.querySelectorAll(`.${styles.text}`);
    hideTitle(currentTitleIndex.current);
    if (titleText) {
      currentTitleIndex.current = currentTitleIndex.current === (titleText.length - 1) ? 0 : currentTitleIndex.current + 1;
    }
    showTitle(currentTitleIndex.current);
  };

  useEffect(() => {
    if (text && textOnScreen) {
      showTitle(0);
      titleSliderInterval = setInterval(() => cycleTitle(), 3000);
      return () => {
        const services = textContainer.current?.querySelectorAll(`.${styles.text}`);
        if (services && services.length > 0) {
          // @ts-ignore
          [...services].forEach(service => {
            service.classList.remove(styles.text_middle);
            service.classList.remove(styles.text_up);
          });
        }
        currentTitleIndex.current = 0;
        clearInterval(titleSliderInterval);
      };
    }
  }, [text, textOnScreen]);
  return (
    <mark
      className={styles.fadeUpTextWrap}
      style={{ width: textWidth }}
      ref={textContainer}
    >
      {Array.isArray(text) ? (
        text.map((category, categoryIndex) => (
          <div
            key={category}
            className={styles.text}
            data-id={categoryIndex}
          >
            <div className={styles.text__container}>
              {category.split('').map((letter, letterIndex) => (
                <span
                  className={styles.letter} // eslint-disable-next-line react/no-array-index-key
                  key={`${letter}/${letterIndex}`}
                >
                  {letter}
                </span>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div
          className={styles.text}
        >
          <div className={styles.text__container}>
            {text.split('')
              .map((letter, index) => (
                <span
                  className={styles.letter} // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {letter === ' ' ? <pre> </pre> : letter}
                </span>
              ))}
          </div>
        </div>
      )}
    </mark>
  );
};

function FadeUpAnimatedText({ text }: { text: string | string[] }) {
  return (
    Array.isArray(text) ? <FadeUpAnimatedTextContent text={text} /> : (
      <>
        {text.split(' ').map((word, index, words) => ( // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <FadeUpAnimatedTextContent text={word} />
            {index !== words.length - 1 && <span>&nbsp;</span>}
          </React.Fragment>
        ))}
      </>
    )
  );
}

export default FadeUpAnimatedText;
