'use client';

import { ReactElement, useEffect, useState } from 'react';
import FadeUpAnimatedText from './FadeUpAnimatedText';

type AnimatedPart = null | string | ReactElement | ReactElement[];
interface Title {
  firstPart: null | string,
  lastPart: null | string,
  animatedPart: AnimatedPart | AnimatedPart[],
}

const inititalTitle: Title = {
  firstPart: null,
  lastPart: null,
  animatedPart: null,
};

export const ANIMATED_TEXT_REGEX = '\\*\\*[^*]+\\*\\*';
export const ANIMATED_TEXTS_REGEX = /\*\*([^*]+)\*\*/g;

export enum AnimationType {
  FULL,
  PARTIAL,
  NONE,
  MULTIPLE,
}

export type AnimatedTitleProps = { title: string, animationType?: AnimationType };

function getFormattedAnimatedTitle({ text, animationType, previousParsedTitle }: {
  text: AnimatedTitleProps['title'],
  animationType: AnimationType,
  previousParsedTitle?: Title,
}): Title {
  if (text) {
    const animatedTitleMatch = text.match(ANIMATED_TEXT_REGEX);
    if (animationType === AnimationType.MULTIPLE) {
      const parts: (null | string | ReactElement)[] = [];
      const animatedTitleMatches = Array.from(text.matchAll(ANIMATED_TEXTS_REGEX));
      let lastIndex = 0;

      animatedTitleMatches.forEach((match, index) => {
        const startIndex = match.index ?? 0;
        const endIndex = startIndex + match[0].length;

        if (lastIndex < startIndex) {
          parts.push(text.slice(lastIndex, startIndex));
        }

        const animatedText = match[1];
        parts.push(<FadeUpAnimatedText
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          text={animatedText}
        />);

        lastIndex = endIndex;
      });

      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }

      return {
        firstPart: null,
        lastPart: null,
        animatedPart: parts,
      };
    }
    if (animationType === AnimationType.FULL) {
      return {
        firstPart: null,
        lastPart: null,
        animatedPart: <FadeUpAnimatedText text={text} />,
      };
    } else if (animatedTitleMatch) {
      const divider = animatedTitleMatch.index;
      const textFirstPart = text.slice(0, divider);
      const textLastPart = divider !== undefined ? text.slice(divider + animatedTitleMatch[0].length) : '';
      return {
        firstPart: textFirstPart,
        lastPart: textLastPart,
        animatedPart: (
          animationType === AnimationType.NONE
            ? animatedTitleMatch[0].slice(2, animatedTitleMatch[0].length - 2)
            : <FadeUpAnimatedText text={animatedTitleMatch[0].slice(2, animatedTitleMatch[0].length - 2)} />
        ),
      };
    } else {
      return ({ ...(previousParsedTitle ?? inititalTitle), firstPart: text });
    }
  }

  return inititalTitle;
}

function AnimatedTitle({ title, animationType = AnimationType.PARTIAL }: AnimatedTitleProps) {
  const [formattedTitle, setFormattedTitle] = useState<Title>(getFormattedAnimatedTitle({
    text: title,
    animationType,
    previousParsedTitle: inititalTitle,
  }));

  useEffect(() => {
    setFormattedTitle(getFormattedAnimatedTitle({
      text: title,
      animationType,
      previousParsedTitle: inititalTitle,
    }));
  }, [title]);

  return (
    <>
      {formattedTitle.firstPart}
      {formattedTitle.animatedPart}
      {formattedTitle.lastPart}
    </>
  );
}

export default AnimatedTitle;
